import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faGithub } from '@fortawesome/free-brands-svg-icons';
import { faPoop, faChartLine } from '@fortawesome/free-solid-svg-icons';
import './App.css';

const App = () => {
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  // Mouse parallax effect
  useEffect(() => {
    const handleMouseMove = (e) => {
      const { innerWidth, innerHeight } = window;
      const x = (e.clientX / innerWidth - 0.5) * 20; // Adjust multiplier for parallax intensity
      const y = (e.clientY / innerHeight - 0.5) * 20;
      setOffset({ x, y });
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  return (
    <div className="App">
      {/* Background div with parallax effect and zoom */}
      <div
        className="background"
        style={{
          transform: `translate(${offset.x}px, ${offset.y}px) scale(1.1)`, // Added scale for zoom effect
          backgroundImage: `url('/background.jpg')`,
          backgroundSize: '110%', // Zoom background to prevent white borders
          backgroundPosition: 'center',
          filter: 'brightness(0.6)', // Adjusted brightness for a more balanced look
        }}
      ></div>
      
      <motion.div
        className="content"
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      >
        <h1 className="title">
          <motion.span
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.5, duration: 1 }}
          >
            Kangaroo Fight Club
          </motion.span>
        </h1>
        <p className="description">
          Join the KFC movement on Solana! With 10,000 unique, hand-crafted NFTs, Kangaroo Fight Club is a symbol of strength and community. Be part of something bigger.
        </p>
        <motion.a
          href="https://launchmynft.io/sol/9504" // Replace with actual minting link
          className="mint-button"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          animate={{ opacity: [0.8, 1, 0.8], transition: { repeat: Infinity, duration: 2 } }}
        >
          MINT NFT
        </motion.a>
        <div className="links">
          <a href="https://x.com/KangarooGangNFT/" target="_blank" rel="noopener noreferrer" className="social-link">
            <FontAwesomeIcon icon={faTwitter} /> Twitter
          </a>
          <a href="https://github.com/solana-labs/ecosystem/blob/9e525b60b623b99dab6e204947212515ab80f158/projects/kangaroofightclub.md" target="_blank" rel="noopener noreferrer" className="social-link">
            <FontAwesomeIcon icon={faGithub} /> GitHub
          </a>
          <a href="https://dexscreener.com" target="_blank" rel="noopener noreferrer" className="social-link">
            <FontAwesomeIcon icon={faChartLine} /> Dexscreener
          </a>
          <a href="https://www.pump.fun/HiffN3CR77eVeLhnpaxXFfNRkJkXTps3vZwGoL8Epump" target="_blank" rel="noopener noreferrer" className="social-link">
            <FontAwesomeIcon icon={faPoop} /> Pump Fun
          </a>
        </div>
      </motion.div>
    </div>
  );
};

export default App;
